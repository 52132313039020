import './App.css';
import Home from './views/Home';
import React, { useLayoutEffect } from 'react';
import '../src/scss/app-loader.scss';
import SocialLogin from './views/SocialLogin';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import LeaveMessageln from './views/LeaveMessageln';
import LeaveMessagefb from './views/LeaveMessagefb';
import Thanks from './views/Thanks';
import SubmitMessageln from './views/SubmitMessageln';
import SubmitMessagefb from './views/SubmitMessagefb';
import Profile from './views/Profile';
import BookingPage from './views/BookingPage';
import { ToastContainer } from 'react-toastify';

function App() {

  return (
    <React.StrictMode>
      <Router>
        <Switch>
          <Route
            exact
            path='/'
            render={() => {
              return <Home />
            }}
          />
          <Route
            exact
            path='/profile'
            render={() => {
              return <Home />
            }}
          />
          <Route
            exact
            path='/social-login'
            render={() => {
              return <SocialLogin />
            }}
          />
          <Route
            exact
            path='/LeaveMessageln'
            render={() => {
              return <LeaveMessageln />
            }}
          />
          <Route
            exact
            path='/LeaveMessagefb'
            render={() => {
              return <LeaveMessagefb />
            }}
          />
          <Route
            exact
            path='/SubmitMessageln'
            render={() => {
              return <SubmitMessageln />
            }}
          />
          <Route
            exact
            path='/SubmitMessagefb'
            render={() => {
              return <SubmitMessagefb />
            }}
          />
          <Route
            exact
            path='/Thanks'
            render={() => {
              return <Thanks />
            }}
          />
          <Route
            exact
            path='/join'
            render={() => {
              return <Profile />
            }}
          />
          <Route
            exact
            path='/booking'
            render={() => {
              return <BookingPage />
            }}
          />
          <Route
            exact
            path='/:name'
            render={() => {
              return <Home />
            }}
          />
        </Switch>
      </Router>
      {/* <RouterProvider router={router} /> */}
      <ToastContainer />
    </React.StrictMode>
  );
}

export default App;
